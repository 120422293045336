import * as api from '../api';
import { FETCH_WEALTHTYPES, FETCH_WEALTHTYPE, CREATE_WEALTHTYPE, DELETE_WEALTHTYPE, UPDATE_WEALTHTYPE } from '../types/index';

export const getWealthTypes = () => async (dispatch) => {
  try {
    const { data } = await api.fetchWealthTypes();
    dispatch({
      type: FETCH_WEALTHTYPES,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}

export const getWealthType = (wealthTypeId) => async (dispatch) => {
  try {
    const { data } = await api.fetchWealthType(wealthTypeId);
    dispatch({
      type: FETCH_WEALTHTYPE,
      payload: data
    })
  } catch (error) {
    console.log(error)
  }
}

export const createWealthType = (wealthTypeParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.createWealthType(wealthTypeParams, getState().auth.token);
    dispatch({
      type: CREATE_WEALTHTYPE,
      payload: data
    });
  } catch (error) {
    console.log(error)
  }
}

export const updateWealthType = (WealthTypeId, wealthTypeParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.updateWealthType(WealthTypeId, wealthTypeParams, getState().auth.token);
    dispatch({
      type: UPDATE_WEALTHTYPE,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
}

export const deleteWealthType = (wealthTypeId) => async (dispatch, getState) => {
  try {
    await api.deleteWealthType(wealthTypeId, getState().auth.token);
    dispatch({
      type: DELETE_WEALTHTYPE,
      payload: wealthTypeId
    });
  } catch (error) {
    console.log(error);
  }
}