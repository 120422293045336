import React from 'react';
import LogOutComponent from '../auth/LogOutComponent';

const LogOut = () => {
  return (
    <div className="home-page">
      <div className="mt-4 jumbotron">
      </div>
      <section>
        <LogOutComponent />
      </section>
    </div>
  )
}

export default LogOut;