import * as api from '../api';
import { LOG_IN, LOG_OUT, UPDATE_USER } from '../types/index';

export const logIn = (logInData) => async (dispatch) => {
  try {
    const { data } = await api.logIn(logInData);
    localStorage.setItem('auth', JSON.stringify(data.auth))
    dispatch({
      type: LOG_IN,
      payload: data
    });
  } catch (error) {
  }
}

export const logOut = () => async (dispatch) => {
  localStorage.removeItem('auth');
  dispatch({
    type: LOG_OUT
  });
}

export const updateEmail = (emailParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.changeEmail(emailParams, getState().auth.token);
    console.log('data', data)
    return dispatch({
      type: UPDATE_USER,
      payload: data
    });
  } catch (error) {
  }
}

export const updatePassword = (passwordParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.changePassword(passwordParams, getState().auth.token);
    dispatch({
      type: UPDATE_USER,
      payload: data.user
    });
  } catch (error) {
  }
}