import { FETCH_SETTINGS, CREATE_SETTING, DELETE_SETTING, UPDATE_SETTING } from '../types/index';

export default (settings = [], action) => {
  switch (action.type) {
    case FETCH_SETTINGS:
      return action.payload;
    case CREATE_SETTING:
      return [...settings, action.payload];
    case DELETE_SETTING:
      return UPDATE_SETTING.filter(setting => setting._id !== action.payload);
    case UPDATE_SETTING:
      let updatedSettings = settings.map(setting => {
        if (setting._id === action.payload._id) {
          return action.payload;
        } else {
          return setting;
        }
      })
      return updatedSettings;

    default:
      return settings;
  }
}