import './App.css';
import { useSelector } from 'react-redux';
import { startUp } from './actions/shared';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Questions from './components/pages/admin/Questions';
import NewQuestion from './components/pages/admin/NewQuestion';
import Register from './components/pages/admin/Register';
import LogIn from './components/pages/LogIn';
import Admin from './components/pages/admin/Admin';
import LogOut from './components/pages/LogOut';
import LoggedOut from './components/pages/LoggedOut';
import EditQuestion from './components/pages/admin/EditQuestion';
import AdminQuestions from './components/pages/admin/Questions';
import WealthTypes from './components/pages/admin/WealthTypes';
import Settings from './components/pages/admin/Settings';
import StartUp from './components/shared/StartUp';
import { ToastContainer } from 'react-toastify';
import Profile from './components/pages/admin/Profile';
import 'react-toastify/dist/ReactToastify.css';
import ApiData from './components/pages/admin/ApiData';

function App() {

  console.log('app')

  const auth = useSelector((state) => state.auth);

  const sendDropOffData = () => {
    console.log('drop off')
  }

  const onLeave = () => {
    alert('are you sure')
  }

  return (
    <div className="App">
      <Router>
        <StartUp />
        <div id="body-container"><ToastContainer />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/questions" element={<Questions />} />
            <Route exact path="/questions/new" element={<NewQuestion />} />
            <Route exact path="/admin/users" element={<Register />} />
            <Route exact path="/admin/profile" element={<Profile />} />
            <Route exact path="/login" element={<LogIn />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/logout" element={<LogOut />} />
            <Route exact path="/loggedout" element={<LoggedOut />} />
            <Route exact path="/admin/questions" element={<AdminQuestions />} />
            <Route exact path="/admin/settings" element={<Settings />} />
            <Route exact path="/admin/questions/:id" element={<EditQuestion />} />
            <Route exact path="/admin/wealthTypes" element={<WealthTypes />} />
            <Route exact path="/admin/apidata" element={<ApiData />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
