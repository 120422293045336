import { useDispatch, useSelector } from 'react-redux';
import { getCurrentQuestionIndex } from '../../actions/questions';


const ProgressBar = () => {
  const questions = useSelector((state) => state.questions);
  const question = useSelector((state) => state.question);
  let progressPercent = getCurrentQuestionIndex(question, questions) / questions.length * 100;
  return (
    <div className='progress-bar'>
      <div className='progress-bar-inner' style={{ width: `${progressPercent || 4}%` }}>-
      </div>
    </div>
  )
}

export default ProgressBar;