import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { answerQuestion, getQuestionWithAnswers, advanceQuestion } from '../../actions/questions';
import { setCurrentAnswer } from '../../actions/answers';

const Answer = ({ answer }) => {
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question);
  const questions = useSelector((state) => state.questions);
  const currentSelectedAnswer = useSelector((state) => state.currentSelectedAnswer);

  let isSelected = null;

  if (!answer) { return <div>...</div> }

  if (currentSelectedAnswer && (currentSelectedAnswer._id === answer._id)) {
    isSelected = currentSelectedAnswer._id === answer._id;
  }

  const handleClick = () => {
    // CHECK THIS
    const answerWithSpecialTag = {
      ...answer,
      specialTag: question.specialTag,
    }

    console.log('here-custom', answerWithSpecialTag)
    dispatch(setCurrentAnswer(answerWithSpecialTag))
    dispatch(advanceQuestion(question, questions, answerWithSpecialTag));

  }

  return (
    <div onClick={() => handleClick()} className={"answer " + (isSelected ? "selected-answer" : "")}>{answer.body}</div>
  )
}

export default Answer;

