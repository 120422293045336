import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { questionsResort, deleteQuestion } from '../../../actions/questions';

const Question = ({ question, setItems, items, index, questions }) => {
  const dispatch = useDispatch();
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index.toString());
  };


  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('index'));
    const newItems = [...questions];
    const [draggedItem] = newItems.splice(dragIndex, 1);
    newItems.splice(dropIndex, 0, draggedItem);
    setItems(newItems);
    dispatch(questionsResort(newItems))
    // saveResort();
  };

  const handleDelete = () => {
    const wantToDelete = window.confirm('Are you sure you want to delete this question?');
    if (wantToDelete) {
      dispatch(deleteQuestion(question._id))
    }
  }

  return (
    <div className='admin-question-list-item'
      draggable
      onDragOver={(e) => handleDragOver(e)}
      onDragStart={(e) => handleDragStart(e, index)}
      onDrop={(e) => handleDrop(e, index)}

    >
      <div>{question.body}</div>
      <p>Question type: {question.type}</p>
      <p>Special tag: {question.specialTag}</p>
      <Link to={`../../admin/questions/${question._id}`}>
        <button className='btn btn-primary mb-4'>Edit</button>
      </Link>
      <br />
      <button onClick={() => handleDelete()} className='btn btn-danger'>Delete</button>
    </div>
  )
}

export default Question;