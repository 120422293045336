import { DELETE_SUBMISSION, ADD_SUBMISSION, ADD_ANSWER_TO_SUBMISSION, BACK_QUESTION } from '../types/index';

export default (submission = {
  answers: []
}, action) => {
  switch (action.type) {
    case DELETE_SUBMISSION:
      return action.payload;
    case ADD_ANSWER_TO_SUBMISSION:
      return { ...submission, answers: [...submission.answers, action.payload] };
    case BACK_QUESTION:
      return { ...submission, answers: action.payload.updatedArray };

    case ADD_SUBMISSION:
      return action.payload;

    default:
      return submission;
  }
}