const QuesitonTypes = () => {

  return (
    <>
      <option value="email">Email</option>
      <option value="dollar">Dollar</option>
      <option value="text">Text</option>
      <option value="select">Multiple Choice</option>
    </>
  )
}

export default QuesitonTypes;
