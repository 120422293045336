import React from 'react';
import QuestionsComponent from '../../../components/admin/questions/Questions';
import Form from '../../../components/admin/questions/Form';
import NewQuestionComponent from '../../questions/NewQuestion';
import Navbar from '../../shared/Navbar';

const Questions = () => {
  return (
    <div className="questions-page overflow-auto">
      <Navbar />
      <div className="mt-4 jumbotron">
        <h1 className="text-center">Questions</h1>
      </div>
      <section>
        <Form />
        <QuestionsComponent />
      </section>
    </div>
  )
}

export default Questions;