import React from 'react';
import Form from '../auth/Form';

const LogIn = () => {
  return (
    <div className="login-page">
      <div className="mt-4 jumbotron">
        <h1 className="text-center">Log In to Admin Dasbboard</h1>
      </div>
      <section>

        <Form />
        <p>If you are not immediately redirected after clicking 'Log In', it means you have entered an incorrect password</p>
      </section>
    </div>
  )
}

export default LogIn;