import { FETCH_WEALTHTYPES, CREATE_WEALTHTYPE, DELETE_WEALTHTYPE, UPDATE_WEALTHTYPE } from '../types/index';

export default (wealthTypes = [], action) => {
  switch (action.type) {
    case FETCH_WEALTHTYPES:
      return action.payload;
    case CREATE_WEALTHTYPE:
      return [...wealthTypes, action.payload];
    case DELETE_WEALTHTYPE:
      return wealthTypes.filter(wealthType => wealthType._id !== action.payload);
    case UPDATE_WEALTHTYPE:
      let updatedWealthType = wealthTypes.map(wealthType => {
        if (wealthType._id === action.payload._id) {
          return action.payload;
        } else {
          return wealthType;
        }
      })
      return updatedWealthType;
    default:
      return wealthTypes;
  }
}