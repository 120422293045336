import { FETCH_QUESTIONS, CREATE_QUESTION, DELETE_QUESTION, UPDATE_QUESTION, QUESTIONS_RESORT } from '../types/index';

export default (questions = [], action) => {
  switch (action.type) {
    case FETCH_QUESTIONS:
      return action.payload;
    case CREATE_QUESTION:
      return [...questions, action.payload];
    case DELETE_QUESTION:
      return questions.filter(project => project._id !== action.payload);
    case QUESTIONS_RESORT:
      return action.payload;
    case UPDATE_QUESTION:
      let updatedQuestions = questions.map(question => {
        if (question._id === action.payload._id) {
          return action.payload;
        } else {
          return question;
        }
      })
      return updatedQuestions;
    default:
      return questions;
  }
}