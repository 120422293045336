import { LOG_IN, UPDATE_USER } from "../types";

export default (user = null, action) => {
  switch (action.type) {
    case LOG_IN:
      return action.payload.user;
    case UPDATE_USER:
      return action.payload.user;
    case "FETCH_USER":
      return action.payload;
    case "LOG_OUT":
      return null;
    default:
      return user;
  }
}