import React from 'react';
import { Link } from 'react-router-dom';

const ReturnLink = ({ destination, anchorText }) => {
  return (
    <Link to={destination}>
      <div className='btn btn-primary mt-3 mb-3'>
        {`<- ${anchorText}`}
      </div>
    </Link>
  )
}

export default ReturnLink;