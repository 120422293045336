import * as api from '../api';
import { FETCH_SETTINGS, UPDATE_SETTING, DELETE_SETTING, CREATE_SETTING } from '../types/index';

export const getSettings = () => async (dispatch) => {
  try {
    const { data } = await api.fetchSettings();
    dispatch({
      type: FETCH_SETTINGS,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}

export const createSetting = (settingParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.createSetting(settingParams, getState().auth.token);
    dispatch({
      type: CREATE_SETTING,
      payload: data
    });
  } catch (error) {
    console.log(error)
  }
}

export const updateSetting = (settingId, settingParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.updateSetting(settingId, settingParams, getState().auth.token);
    dispatch({
      type: UPDATE_SETTING,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
}

export const deleteSetting = (settingId) => async (dispatch, getState) => {
  try {
    await api.deleteSetting(settingId, getState().auth.token);
    dispatch({
      type: DELETE_SETTING,
      payload: settingId,
    });
  } catch (error) {
    console.log(error);
  }
}
