import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestion } from '../../../actions/questions';
import Answers from '../answers/Answers';
import { newToast } from '../../../actions/shared';
import QuesitonTypes from '../../shared/QuestionTypes';
import { getQuestionWithAnswers } from '../../../actions/questions';
const EditQuestion = ({ question }) => {
  const dispatch = useDispatch();
  const notify = (message) => dispatch(newToast(message));

  const [formData, setFormData] = useState({
    body: question.body,
    type: question.type,
    specialTag: question.specialTag,
  });

  useEffect(() => {
    setFormData({
      body: question.body,
      type: question.type,
      specialTag: question.specialTag,

    })
  }, [question])

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    dispatch(updateQuestion(question._id, formData));
    newToast("Question Updated.")
  }

  return (
    <div><form onSubmit={handleSubmit} className='form-group'>
      <label htmlFor='body'>Body</label>
      <input name="body" onChange={(e) => setFormData({ ...formData, body: e.target.value })} value={formData.body} className="form-control center" id="body" aria-describedby="body" type="text" />
      <label htmlFor='type'>Type</label>
      <select name="type" onChange={(e) => setFormData({ ...formData, type: e.target.value })} value={formData['type']} className='w-half' id='type' aria-describedby="type">
        <QuesitonTypes />
      </select>
      <label htmlFor='body'>Special Tag </label>
      <div className='alert alert-warning'>
        <p>
          If special tag is "email" or "name", it must be the exact tag ID in ActiveCampaign (Ex: "19", "20").
        </p>
        <p>
          This number corrosponds to the custom field ID in ActiveCampaign which can be seen in ApiData.
        </p>
      </div>
      <input name="specialTag" onChange={(e) => setFormData({ ...formData, specialTag: e.target.value })} value={formData.specialTag} className="form-control center" id="specialTag" aria-describedby="specialTag" type="text" />
      <button type="submit" className="btn btn-primary">Save Question</button>
    </form>
    </div>
  )
}

export default EditQuestion;