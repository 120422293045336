import { END_QUIZ, ADD_SUBMISSION, START_QUIZ } from '../types/index';

const QUIZ_START_STATE = "QUIZ_START";
const QUIZ_END_STATE = "QUIZ_END";
const SUBMITTED_STATE = "SUBMITTED";

export default (quizState = '', action) => {
  switch (action.type) {
    case START_QUIZ:
      return QUIZ_START_STATE;
    case END_QUIZ:
      return QUIZ_END_STATE;
    case ADD_SUBMISSION:
      return SUBMITTED_STATE;
    default:
      return quizState;
  }
}