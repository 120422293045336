import { Link } from "react-router-dom";
import { addScroll } from "../../lib/lib";
const Navbar = () => {
  addScroll();
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <ul className="mb-2 mb-lg-0">
          <li className="hover nav-item">
            <Link to="/">Main Quiz Page</Link>
          </li>
          <li className="hover nav-item">
            <Link to="/admin">Dashboard Home</Link>
          </li>
          <li className="hover nav-item">
            <Link to="/admin/profile">Profile</Link>
          </li>
          <li className="hover nav-item">
            <Link to='/admin/questions' >Questions</Link>
          </li>
          <li className="hover nav-item">
            <Link to='/admin/wealthtypes'>WealthTypes</Link>
          </li>
          <li className="hover nav-item">
            <Link to='/admin/settings'>Settings</Link>
          </li>
          <li className="hover nav-item">
            <Link to='/admin/apidata'>ApiData</Link>
          </li>
          <li className="hover nav-item">
            <Link to='../logout'>Log Out</Link>
          </li>
        </ul>
      </div>
    </nav >
  )
}
export default Navbar;








/***

   <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="hover navbar-item">
            <Link to='../questions'>Questions</Link>
          </li>
          <li className="hover navbar-item">
            <Link to='../settings'>Settings</Link>
          </li>
          <li className="hover navbar-item">
            <Link to='../users'>Users</Link>
          </li>
          <li className="hover navbar-item">
            <Link to='../logout'>Log Out</Link>
          </li>
        </ul>

      </div>

    </nav >
 */