import Answer from "./Answer";
import Form from "./Form";
import { useDispatch, useSelector } from 'react-redux';
import { getWealthTypes } from "../../../actions/wealthTypes";
import { useEffect } from "react";
import { getAnswers } from "../../../actions/answers";
import { useParams } from 'react-router-dom';
import { getQuestionWithAnswers } from "../../../actions/questions";

const Answers = () => {
  const dispatch = useDispatch();
  const wealthTypes = useSelector((state) => state.wealthTypes);
  const answers = useSelector((state) => state.answers);

  const questionId = useParams().id;

  useEffect(() => {
    dispatch(getQuestionWithAnswers(questionId));
  }, []);


  if (!wealthTypes || !answers) {
    return <div>Loading...</div>
  }

  return (
    <div className="admin-answers">
      <h2>New Answer</h2>
      <Form wealthTypeOptions={wealthTypes} questionId={questionId} />
      <h3>Current Answers</h3>
      <hr />
      <div>
        {answers.map((a) => <Answer answer={a} key={`answer-${a._id}`} />)}
      </div>
    </div>
  )
}

export default Answers;