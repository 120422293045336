import { useEffect } from "react";
import Navbar from "../../shared/Navbar";
import { getAcFields, getAcTags, getRecentContacts } from "../../../actions/api";
import { useDispatch, useSelector } from "react-redux";
const ApiData = () => {
  const dispatch = useDispatch();
  const acFields = useSelector((state) => state.acFields);
  const acTags = useSelector((state) => state.acTags);
  const auth = useSelector((state) => state.auth);
  const recentContacts = useSelector((state) => state.recentContacts);
  if (auth) {
    import('./admin.css');
  }

  useEffect(() => {
    dispatch(getAcFields());
    dispatch(getAcTags());
    dispatch(getRecentContacts());
  }, [])


  return (
    <div>
      <Navbar />

      <h2>Tags containing 'WTA'</h2>
      <ul>
        {acTags.map((tag) => {
          return (
            <li>{`${tag.tag} ${tag.id}`}</li>)
            ;
        })}
      </ul>
      <h2>Fields</h2>
      <ul>
        {acFields.map((field) => {
          return (
            <li>{`${field.title} ${field.id}`}</li>)
            ;
        })}
      </ul>
      <h2>Recent Contats</h2>
      <ul>
        {recentContacts.map((contact) => {
          return (
            <li>
              {`${contact.email} - ID: ${contact.id}`}
              <ul>
                <p>Fields</p>
                {contact.fieldValues.map((field) => {
                  return (
                    <li>{`${field.field} ${field.value}`}</li>)
                    ;
                })}
              </ul>
              <ul>
                <p>Tags</p>
                {contact.tags.map((tag) => {
                  return (
                    <li>{`tag: ${tag.tag} `}</li>);
                })}
              </ul>
              {contact.tags.map((tag) => { })}
            </li>)
            ;
        })}
      </ul>
    </div>

  )
}

export default ApiData;