import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth'
import { useNavigate } from 'react-router-dom';

const LogOutComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(logOut())
  navigate('/loggedout')


  return (
    <div>
      <p>Logging out...</p>

    </div>
  )
}

export default LogOutComponent;