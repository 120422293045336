import React, { useEffect } from 'react';
import { startUp } from '../../actions/shared';
import { useDispatch, getState } from 'react-redux';

const StartUp = () => {

  console.log('StartUp')

  const dispatch = useDispatch();
  useEffect(() => {
    console.log('dispatching startUp')
    dispatch(startUp());
  }, [])

  return (
    <div></div>
  )
}

export default StartUp;