import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createQuestion } from '../../../actions/questions'
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Navbar from '../../shared/Navbar';

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  if (auth) {
    import('./admin.css');
  }

  useEffect(() => {
    if (!auth) {
      navigate('/login')
    }
  }, []);

  return (
    <div className="admin-page">
      <Navbar />
      <div className='alert alert-warning'>
        If you have any issues with the data on page not loading correctly, if there are scrolling issues, or any other issues, navigate to a different page and come back. Refreshing will not work, you must navigate away and come back. If you receive a "This page is not found message" return to the main quiz page and log back in. If you were doing anything like updating your email or password, the changes should be shown - even if the page was not found before when changing your information.
      </div>
      <div className="mt-4 jumbotron">
        <h1 className="text-center">Admin Dasbboard</h1>
      </div>
      <section className='d-grid gap-2'>
        <Link to='./questions'>
          <div className='btn btn-primary center margin-v'>Questions</div>
        </Link>
        <Link to='./settings'>
          <div className='btn btn-primary center margin-v'>Settings</div>
        </Link>
        <Link to='./users'>
          <div className='btn btn-primary center margin-v'>Users</div>
        </Link>

      </section>
    </div>
  )
}

export default Admin;
