import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Form from '../../users/Form';

const NewQuestion = () => {

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth) {
      navigate('/login')
    }
  }, []);

  return (
    <div className="new-question-page">
      <h1>Register a New User</h1>
      <Form />
    </div>
  )
}

export default NewQuestion;