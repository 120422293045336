import React, { useState } from 'react';
import { createWealthType } from '../../../actions/wealthTypes'
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { newToast } from '../../../actions/shared'
const Form = () => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    wealthTypeName: '',
    link: '',
    activeCampaignTagId: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createWealthType(formData));
    setFormData({
      ...formData,
      wealthTypeName: '',
      link: '',
      activeCampaignTagId: '',
    })
    newToast('Created new Wealth Type.')
  }

  return (
    <div className='new-wealth-type-form margin-b w-half center'>
      <h2>Create new Wealth Type</h2>
      <div>
        <form onSubmit={handleSubmit} className='form-group border border-primary p-3'>
          <label htmlFor='wealthTypeName'>Name</label>
          <input name="wealthTypeName" onChange={(e) => setFormData({ ...formData, wealthTypeName: e.target.value })} value={formData.wealthTypeName} className="form-control center" id="wealthTypeName" aria-describedby="wealthTypeName" type="text" />
          <label htmlFor='link'>Link</label>
          <input name="link" onChange={(e) => setFormData({ ...formData, link: e.target.value })} value={formData.link} className="form-control center" id="link" aria-describedby="link" type="text" />
          <p>Links MUST be in the following format: https://link.com</p>
          {/* <label htmlFor='activeCampaignTagId'>activeCampaignTagId</label>
          <input name="activeCampaignTagId" onChange={(e) => setFormData({ ...formData, activeCampaignTagId: e.target.value })} value={formData.activeCampaignTagId} className="form-control center" id="activeCampaignTagId" aria-describedby="activeCampaignTagId" type="text" />
          <p>Active Campaign Tag Id Comes from AC API</p> */}
          <button type="submit" className="btn btn-primary mt-3">Save Wealth Type</button>
        </form>
      </div>
    </div>
  )
}

export default Form;