import { FETCH_ANSWERS, CREATE_ANSWER, DELETE_ANSWER, UPDATE_ANSWER, GET_ANSWERS_FOR_QUESION } from '../types/index';

export default (answers = [], action) => {
  switch (action.type) {
    case FETCH_ANSWERS:
      return action.payload;
    case CREATE_ANSWER:
      return [...answers, action.payload];
    case DELETE_ANSWER:
      return answers.filter(project => project._id !== action.payload);
    case UPDATE_ANSWER:
      let updatedAnswers = answers.map(answer => {
        if (answer._id === action.payload._id) {
          return action.payload;
        } else {
          return answer;
        }
      })
      return updatedAnswers;
    case GET_ANSWERS_FOR_QUESION:
      return action.payload;
    default:
      return answers;
  }
}