import React from 'react';
import { Link } from 'react-router-dom';
const LoggedOut = () => {
  return (
    <div className="home-page">
      <div className="mt-4 jumbotron">
        <h1 className="text-center">You Have Successfully Logged Out</h1>
      </div>
      <section className='row'>
        <div className="container mt-4 col-4 d-flex justify-content-center">
          <Link to="/login" className="btn btn-primary">Login</Link>
        </div>
      </section>
    </div>
  )
}

export default LoggedOut;