import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createQuestion } from '../../../actions/questions';
import { newToast } from '../../../actions/shared';
import QuesitonTypes from '../../shared/QuestionTypes';
const Form = () => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    body: '',
    type: 'email',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createQuestion(formData));
    setFormData({
      body: "",
      type: "",
    })
    newToast("Question created.")
  }

  return (
    <div className='new-question-form'>
      <h2>Create new Question</h2>
      <div>
        <form onSubmit={handleSubmit} className='form-group'>
          <label htmlFor='body'>Body</label>
          <input required minlength='2' name="body" onChange={(e) => setFormData({ ...formData, body: e.target.value })} value={formData.body} className="form-control center w-half" id="body" aria-describedby="body" type="text" />
          <label htmlFor='type'>Question Type</label>
          <select required name="type" onChange={(e) => setFormData({ ...formData, type: e.target.value })} value={formData.type} className='w-half'>
            <QuesitonTypes />
          </select>
          <button type="submit" className="btn btn-primary">Save Question</button>
        </form>
      </div>
    </div>
  )
}

export default Form;