import React, { useEffect } from 'react';
import NewQuestionComponent from '../../questions/NewQuestion';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NewQuestion = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth) {
      navigate('/login')
    }
  }, []);
  return (
    <div className="new-question-page center">
      <NewQuestionComponent />
    </div>
  )
}

export default NewQuestion;