import * as api from '../api';
import { GET_AC_FIELDS, GET_AC_TAGS, GET_RECENT_CONTACTS } from '../types/index';
export const getAcFields = () => async (dispatch) => {
  try {
    const { data } = await api.getAcFields();
    dispatch({
      type: GET_AC_FIELDS,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}

export const getAcTags = () => async (dispatch) => {
  try {
    const { data } = await api.getAcTags();
    dispatch({
      type: GET_AC_TAGS,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}

export const getRecentContacts = () => async (dispatch) => {
  try {
    const data = await api.getAcContacts();

    console.log('data', data);
    dispatch({
      type: GET_RECENT_CONTACTS,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}