import { advanceQuestion } from '../../actions/questions';
import { useDispatch, useSelector } from 'react-redux';
import { endQuiz } from '../../actions/quizState';



const NavButton = ({ onLastQuestion }) => {
  const dispatch = useDispatch();

  const question = useSelector((state) => state.question);
  const questions = useSelector((state) => state.questions);
  const currentSelectedAnswer = useSelector((state) => state.currentSelectedAnswer)

  const handleClick = () => {
    dispatch(advanceQuestion(question, questions, currentSelectedAnswer));
    // if (onLastQuestion()) {
    //   dispatch(endQuiz());
    // }
  }

  let alertMessage = 'Your answer is not long enough. Please enter a valid answer.';

  const handleAlert = () => {
    alert(alertMessage)
  }

  const invalidEmail = (emailAddress) => {
    const re = /\S+@\S+\.\S+/;
    return !re.test(emailAddress);
  }

  const inputIsInvalid = () => {

    if (currentSelectedAnswer.specialTag && (currentSelectedAnswer.value.length < 3)) {
      alertMessage = 'Your answer is not long enough. Please enter a valid answer.';
      return true;
    }
    if (currentSelectedAnswer.specialTag === 'email' && (currentSelectedAnswer.value.length < 5 || invalidEmail(currentSelectedAnswer.value))) {
      alertMessage = 'Please enter a valid email address.';
      return true;
    }
    return false;

  }

  if (!currentSelectedAnswer || inputIsInvalid()) {
    return (<div onClick={() => handleAlert()} className='next-button custom-blue-btn '>Continue</div>);
  }


  return (<div onClick={() => handleClick()} className='next-button custom-blue-btn  hover'>Continue</div>);
}

export default NavButton;

