import { SET_REDIRECT_URL, CLEAR_REDIRECT_URL } from '../types';

export default (redirectUrl = null, action) => {
  switch (action.type) {
    case SET_REDIRECT_URL:
      console.log('redirectUrl', action.payload)
      return action.payload
    case CLEAR_REDIRECT_URL:
      return null;
    default:
      return redirectUrl;
  }
}