import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionWithAnswers } from '../../../actions/questions';
import EditQuestionForm from '../../../components/admin/questions/EditQuestionForm';
import { useParams } from 'react-router-dom';
import { deleteQuestion } from '../../../actions/questions';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../shared/Navbar';
import ReturnLink from '../../../components/shared/ReturnLink';
import Answers from '../../admin/answers/Answers';
import { newToast } from '../../../actions/shared';

const EditQuestion = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const questionId = useParams().id

  const question = useSelector(state => state.question);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth) {
      navigate('/login')
    }
  }, []);
  if (auth) {
    import('./admin.css');
  }

  console.log('getting question with id of', questionId);

  useEffect(() => {
    dispatch(getQuestionWithAnswers(questionId));
  }, [questionId])

  const handleSubmit = (event) => {
    event.preventDefault();
    let wantToDelete = window.confirm('Are you sure you want to delete this question?');
    if (wantToDelete) {
      dispatch(deleteQuestion(questionId));
      navigate('/admin/questions');
    }
    newToast("Question deleted.")
  }

  if (!question) { return <div>Loading...</div> }

  const renderQuestionBasedOnType = () => {
    if (question.type === 'select') {
      return <Answers />
    }
  }

  return (
    <div>
      <Navbar />
      <ReturnLink anchorText='Back to questions' destination='../admin/questions' />
      <div className='container'>
        <div className="row">


          <h1>Editing Question</h1>
          <div class="alert alert-warning" role="alert">
            If any of the data is not correct for the current question  - for example, the body or answers are incorrect - refreshing the page will fix it.
          </div>
          <div> <EditQuestionForm question={question} />
            {renderQuestionBasedOnType()}
            <form onSubmit={handleSubmit}>
              <button type='submit' className=' btn danger'>Delete</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditQuestion;