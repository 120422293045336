export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const CREATE_USER = "CREATE_USER";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";

// QUESTIONS
export const FETCH_QUESTION = "FETCH_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const SET_QUESTION = "SET_QUESTION";
export const GET_ANSWERS_FOR_QUESION = "GET_ANSWERS_FOR_QUESION";
export const QUESTIONS_RESORT = "QUESTIONS_RESORT";
export const BACK_QUESTION = "BACK_QUESTION";

// ANSWERS
export const FETCH_ANSWER = "FETCH_ANSWER";
export const DELETE_ANSWER = "DELETE_ANSWER";
export const FETCH_ANSWERS = "FETCH_ANSWERS";
export const CREATE_ANSWER = "CREATE_ANSWER";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const SET_CURRENT_SELECTED_ANSWER = "SET_CURRENT_SELECTED_ANSWER";

// WEALTH TYPES
export const FETCH_WEALTHTYPE = "FETCH_WEALTHTYPE";
export const DELETE_WEALTHTYPE = "DELETE_WEALTHTYPE";
export const FETCH_WEALTHTYPES = "FETCH_WEALTHTYPES";
export const CREATE_WEALTHTYPE = "CREATE_WEALTHTYPE";
export const UPDATE_WEALTHTYPE = "UPDATE_WEALTHTYPE";


export const START_UP = "START_UP";

// QUIZ STATE
export const END_QUIZ = "END_QUIZ";
export const START_QUIZ = "START_QUIZ";

// SETTINGS
export const DELETE_SETTING = "DELETE_SETTING";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const CREATE_SETTING = "CREATE_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";

// SUBMISSIONS
export const DELETE_SUBMISSION = "DELETE_SUBMISSION";
export const ADD_SUBMISSION = "ADD_SUBMISSION";
export const ADD_ANSWER_TO_SUBMISSION = "ADD_ANSWER_TO_SUBMISSION";

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const CLEAR_REDIRECT_URL = "CLEAR_REDIRECT_URL";

export const GET_AC_TAGS = "GET_AC_TAGS";
export const GET_AC_FIELDS = "GET_AC_FIELDS";

export const GET_RECENT_CONTACTS = "GET_RECENT_CONTACTS";