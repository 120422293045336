import { SET_CURRENT_SELECTED_ANSWER, BACK_QUESTION } from '../types';

const defaultState = JSON.parse(localStorage.getItem('auth'));

export default (currentAnswer = null, action) => {
  switch (action.type) {
    case SET_CURRENT_SELECTED_ANSWER:
      return action.payload;
    case BACK_QUESTION:
      return action.payload.previousAnswer;
    default:
      return currentAnswer;
  }
}