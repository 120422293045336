import Answer from './Answer';
import { useDispatch, useSelector } from 'react-redux';

const Answers = () => {
  const question = useSelector((state) => state.question);
  const answers = useSelector((state) => state.answers);

  return (
    <div className='answers-container'>
      {answers.map((answer) => <Answer answer={answer} key={`answer-${answer._id}`} />)}
    </div>
  )
}

export default Answers;

