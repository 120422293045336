import React, { useEffect } from 'react';
import WealthTypeForm from '../../admin/wealthTypes/Form';
import WealthType from '../../admin/wealthTypes/WealthType';
import { useDispatch, useSelector } from 'react-redux';
import { getWealthTypes } from '../../../actions/wealthTypes';
import NavBar from '../../shared/Navbar';
import { useNavigate } from 'react-router-dom';

const WealthTypes = () => {

  const dispatch = useDispatch();
  const wealthTypes = useSelector((state) => state.wealthTypes);


  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth) {
      navigate('/login')
    }
  }, []);

  if (auth) {
    import('./admin.css');
  }
  useEffect(() => {
    dispatch(getWealthTypes());
  }, [])

  if (!wealthTypes) {
    return <div></div>
  }

  return (
    <div className="settings-page">
      <NavBar />
      <h1>Wealth Types</h1>
      <WealthTypeForm />
      <div className='w-half center t-left'>
        {wealthTypes.map((wType, index) => <WealthType wealthType={wType} key={`wealthType-${index}`} />)}

      </div>
    </div>
  )
}

export default WealthTypes;