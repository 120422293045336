import { combineReducers } from 'redux';

import auth from './auth';
import user from './user';
import questions from './questions';
import question from './question';
import answers from './answers';
import wealthTypes from './wealthTypes';
import currentSelectedAnswer from './currentSelectedAnswer';
import quizState from './quizState';
import settings from './settings';
import submission from './submission';
import redirectUrl from './redirectUrl';
import acFields from './acFields';
import acTags from './acTags';
import recentContacts from './recentContacts';


export default combineReducers({
  auth,
  user,
  questions,
  answers,
  question,
  wealthTypes,
  currentSelectedAnswer,
  quizState,
  settings,
  submission,
  redirectUrl,
  acFields,
  acTags,
  recentContacts,
});