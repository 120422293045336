import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../shared/Navbar';
import { getSettings, createSetting, deleteSetting, updateSetting } from '../../../actions/settings';
import Setting from '../../admin/settings/Setting';
import SettingsForm from '../../admin/settings/SettingsForm';
import { useNavigate } from 'react-router-dom';


const Settings = () => {

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth) {
      navigate('/login')
    }
  }, []);

  useEffect(() => {
    dispatch(getSettings());
  }, [])

  if (!settings) {
    return <div></div>
  }

  return (
    <div className="settings-page">
      <NavBar />
      <h1>Settings</h1>
      <div className="alert alert-warning" role="alert">
        The below values are currently NOT being used in the app. They are here for future use.
      </div>      <p>WARNING: Do not change these unless you know what you're doing</p>
      <SettingsForm />

      <div className='w-half center t-left'>
        {settings.map((set, index) => <Setting setting={set} key={`setting-${set._id}`} />)}

      </div>
    </div>
  )
}

export default Settings;