import React, { useEffect, useState } from 'react';
import QuestionForm from '../questions/Form';
import ReturnLink from '../shared/ReturnLink';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionWithAnswers } from '../../actions/questions';
import Answers from '../answers/Answers';
import { setCurrentAnswer } from '../../actions/answers';
import TextAnswer from '../answers/TextAnswer';

const Question = ({ question }) => {
  const dispatch = useDispatch();
  const answers = useSelector((state) => state.answers);
  const selectedAnswer = useSelector((state) => state.selectedAnswer);

  useEffect(() => {
    dispatch(getQuestionWithAnswers(question._id));
  }, [])

  const [formData, setFormData] = useState({
    value: '',
    questionId: question._id,
  });

  if (question.type !== 'select') {
    return (
      <div className="question-page">
        <div className="row">
          <h2 className="mt-4">{question.body}</h2>
        </div>
        <div className="answers-flex-container">
          <TextAnswer question={question} initValue="" key={`${question._id}-text-answer`} />
        </div>
      </div>
    )

  }

  return (
    <div className="question-page">
      <div className="row">
        <h2 className="mt-4">{question.body}</h2>
      </div>
      <div className="answers-flex-container">
        <Answers />
      </div>
    </div>
  )
}

export default Question;