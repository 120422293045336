import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { newToast } from "../../../actions/shared";
import { updateEmail, updatePassword } from "../../../actions/auth";
import { getUser } from "../../../actions/users";
import Navbar from "../../shared/Navbar";

const Profile = () => {

  const dispatch = useDispatch();
  const notify = (message) => dispatch(newToast(message));
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  useEffect(async () => {
    const data = await dispatch(getUser(auth.userId))
    console.log('data', data)
    console.log('user', user)
  }, [])

  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isMatch, setIsMatch] = useState(true);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const wantToChange = window.confirm(`Are you sure you want to change your email to ${email} `);
    if (wantToChange) {
      const originalEmail = user.email;
      const newEmail = email;
      console.log('originalEmail', originalEmail)
      console.log('newEmail', newEmail)
      const data = await dispatch(updateEmail({ originalEmail, newEmail }));
      window.location.reload();

    }


    console.log('email', email)
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const asyncFunction = async () => {
      const wantToChange = window.confirm(`Are you sure you want to change your password?`);
      if (wantToChange) {
        const params = { userId: user._id, password }
        const returnData = await dispatch(updatePassword(params));
        window.location.reload();

      }
    }
    asyncFunction();
    console.log('passwords', password, confirmPassword)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePasswords(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validatePasswords(password, e.target.value);
  };

  const validatePasswords = (pwd, confirmPwd) => {
    setIsMatch(pwd === confirmPwd);
  };

  const getBorderClass = () => {
    return isMatch ? '' : 'border border-danger';
  };

  if (!user) {
    return <div>Loading...</div>
  }

  if (!email) {
    setEmail(user.email)

    return <div>Loading...</div>
  }

  return (
    <div className="profile-page">
      <Navbar />
      <h1>Profile</h1>
      <div className="mt-4 row">
        <form className="mb-3 col-6 mx-auto" onSubmit={handleEmailSubmit}>
          <label className="">
            Email
            <input onChange={(e) => setEmail(e.target.value)} value={email} name="email" type="email" minLength={4} placeholder="Email" className="form-control mb-3" />
          </label>
          <button className="btn btn-primary d-block center">Save Email</button>
        </form>
      </div>


      <div className=" mt-4 row d-flex justify-content-center">
        <form className="col-4" onSubmit={(e) => handlePasswordSubmit(e)}>
          <div className="form-group">
            <h2>Change password</h2>
            <label>Password</label>
            <input
              type="password"
              className={`form-control ${getBorderClass()}`}
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              className={`form-control ${getBorderClass()}`}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>
          {!isMatch && (
            <div className="text-danger">
              Passwords do not match.
            </div>
          )}
          <button disabled={!isMatch || password.length < 5} className="btn btn-primary d-block center mt-3">Save Password</button>
        </form>
      </div>
    </div>
  );
};

export default Profile;