import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentAnswer } from '../../actions/answers';

const TextAnswer = ({ question, initValue }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    value: initValue,
    body: question.body,
    questionId: question._id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      value: e.target.value,
      specialTag: question.specialTag,
    });
    dispatch(setCurrentAnswer({
      ...formData,
      value: e.target.value,
      specialTag: question.specialTag,
    }))
  };

  if (question.type === 'email') {
    return (<div className='text-answer'>
      <input minLength={5} type="email" className='form-control mt-5 w-half center custom-input' value={formData.value} onChange={handleChange} />
    </div>)
  }

  return (
    <div className='text-answer'>
      <input type="text" className='form-control mt-5 w-half center custom-input' value={formData.value} onChange={handleChange} />
    </div>
  );
};

export default TextAnswer;