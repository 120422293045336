import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newToast } from '../../../actions/shared';
import { createAnswer } from '../../../actions/answers';
import { getWealthTypes } from '../../../actions/wealthTypes';

const Form = ({ questionId }) => {
  const dispatch = useDispatch();
  const wealthTypeOptions = useSelector((state) => state.wealthTypes);

  const [formData, setFormData] = useState({
    body: '',
    wealthTypeId: '',
    points: '',
    openToggle: false
  });

  const defaultWealthType = wealthTypeOptions[0];


  console.log('defaultWealthType', defaultWealthType)



  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createAnswer({ ...formData, questionId }));
    newToast("New Answer created.")
    console.log('formData', formData)
    setFormData({
      body: '',
      wealthTypeName: defaultWealthType.wealthTypeName,
      points: '',
      openToggle: false,
    })
  }

  if (wealthTypeOptions.length < 1) {
    return <div>...</div>
  }

  console.log('wealthTypeOptions', wealthTypeOptions)

  const renderWealthSelection = () => {
    return (
      <select defaultValue={defaultWealthType._id} value={formData.wealthTypeName} onChange={(e) => setFormData({ ...formData, wealthTypeName: e.target.value })} name="wealthTypeName" id="wealthTypeName" >
        {wealthTypeOptions.concat({ _id: "stub", wealthTypeName: "NULL" }).map((op) => {
          return <option value={op._id}>{op.wealthTypeName}</option>
        })}
      </select >
    )
  }

  return (
    <div>
      <div className=''>
        <form onSubmit={handleSubmit} className='form-group form-control border-primary'>
          <div className='cols margin-b'>
            <label htmlFor='body'>Answer</label>
            <input name="body" onChange={(e) => setFormData({ ...formData, body: e.target.value })} value={formData.body} className="form-control center" id="body" aria-describedby="body" type="text" />
          </div>
          <div className='cols margin-b'>
            <label htmlFor='wealthType'>Wealth Type</label>
            {renderWealthSelection()}
          </div>
          <button type="submit" className="btn btn-primary">Save New Answer</button>
        </form>
      </div>
    </div>
  )
}

export default Form;