import React, { useState, useEffect } from 'react';
import Question from '../../components/questions/Question';
import { getQuestions, getAnswersForQuestionWithId, advanceQuestion } from '../../actions/questions';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentAnswer } from '../../actions/answers';
import { endQuiz } from '../../actions/quizState';
import { useNavigate, useBeforeUnload, Link } from 'react-router-dom';
import logo from '../../images/logo-long.png';
import NextButton from '../shared/NextButton';
import ProgressBar from '../shared/ProgressBar';
import BackButton from '../shared/BackButton';
import Navbar from '../shared/Navbar';
import { removeScroll } from '../../lib/lib';
const Home = () => {
  console.log('home page')
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions);
  const question = useSelector((state) => state.question);
  const answer = useSelector((state) => state.answer);
  const quizState = useSelector((state) => state.quizState)
  const redirectUrl = useSelector((state) => state.redirectUrl);
  const wealthTypes = useSelector((state) => state.wealthTypes);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  console.log('set up store values')

  useBeforeUnload((event) => {
    if (!redirectUrl) {
      dispatch(endQuiz());
    }
  })

  removeScroll();

  const [currentState, setCurrentState] = useState({
    question: question,
  });

  // useEffect(() => {
  //   if (quizState === "QUIZ_END") {
  //     dispatch(endQuiz());
  //     console.log('should redirect');
  //   }
  // }, [quizState])


  useEffect(() => {
    if (redirectUrl) {
      console.log('wealthtypes', wealthTypes)
      console.log('redirectUrl', redirectUrl)
      let newUrl = wealthTypes.find((wealthType) => {
        console.log('wealthType', wealthType)
        return wealthType.wealthTypeName === redirectUrl
      })
      console.log('should redirect', newUrl)
      setTimeout(() => {
        window.location.href = newUrl.link;
      }, 1000)
    }
  }, [redirectUrl])

  const onLastQuestion = () => {
    return question === questions[questions.length - 1]
  }

  const onFirstQuestion = () => {
    const first = question._id === questions[0]._id;
    return first;
  }

  const renderNavBar = () => {
    if (auth) {
      return <Navbar />
    } else {
      return <div></div>
    }
  }

  if (!question) { return <div>Loading...</div> }

  const renderNextButton = () => {
    if (question.type !== 'select') {
      return <NextButton onLastQuestion={onLastQuestion} />
    } else {
      return <span></span>
    }

  }

  // const sendDropOffData = () => {
  //   window.removeEventListener('beforeunload', handleBeforeUnload)
  //   if (!sentData) {
  //     dispatch(endQuiz(question, questions));
  //     sentData = true;
  //   }
  // }

  // const handleBeforeUnload = (event) => {
  //   event.stopPropagation()
  //   sendDropOffData();
  // }

  console.log('rending home')



  return (
    <div className="home-page">
      {renderNavBar()}
      <div className='main-nav'>
        <img src={logo} alt="logo" className='logo' />
        <div className='hidden-button'>
          <Link to={'admin'}>A</Link>
        </div>
      </div>
      <main className=''>
        <div className='main-header'>
          <h1>FIND THE EXACT WAY YOU'RE DESIGNED TO BUILD WEALTH</h1>
        </div>
        <ProgressBar />
        <Question question={question} />
        <div className='nav-buttons'>
          {/* <BackButton onFirstQuestion={onFirstQuestion} /> */}
          {renderNextButton()}
        </div>
      </main>



    </div>
  )
}

export default Home;