import * as api from '../api';
import { END_QUIZ } from '../types/index';

export const endQuiz = (currentQuestion, allQuestions) => async (dispatch, getState) => {

  const dropoffData = {
    currentQuestion: getState().question,
    allQuestions: getState().questions
  }
  console.log('dropOffData', currentQuestion);
  console.log('dropOffData', allQuestions);
  if (getState().quizState !== "SUBMITTED_STATE") {
    api.sendDropOff(dropoffData);

  }

  dispatch({
    type: END_QUIZ,
    payload: "END_QUIZ",
  })
}