import { LOG_IN, CREATE_USER, LOG_OUT, UPDATE_USER } from '../types';

const defaultState = JSON.parse(localStorage.getItem('auth'));

export default (auth = defaultState, action) => {
  switch (action.type) {
    case LOG_IN:
      console.log('action.payload', action.payload)
      return {
        token: action.payload.auth.token,
        loggedIn: true,
        userId: action.payload.user._id
      }
    case CREATE_USER:
      return {
        token: action.payload.token, loggedIn: action.payload.loggedIn
      }
    case LOG_OUT:
      return false;
    default:
      return auth;
  }
}