import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logIn } from '../../actions/auth'
import { useNavigate } from 'react-router-dom';

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  useEffect(() => {
    if (auth) {
      navigate('/')
    } else {
      wrongPassword = true;
    }
  }, [auth])

  let wrongPassword = false;

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData)
    dispatch(logIn(formData));
    if (auth) {
      navigate('/')
    } else {
      wrongPassword = true;
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor='email'>Email</label>
          <input name="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} value={formData.name} className="form-control center" id="email" aria-describedby="body" type="email" />
          <label htmlFor='password'>Password</label>
          <input name='password' onChange={(e) => setFormData({ ...formData, password: e.target.value })} value={formData.name} className="form-control center" id="password" aria-describedby="body" type="password" />
        </div>
        <button type="submit" className="btn btn-primary">Log In</button>

        {wrongPassword && <div className='incorrect-login danger w-half center'>
          Problem logging in</div>}
      </form>
    </div>
  )
}

export default Form;