import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAnswer, updateAnswer } from '../../../actions/answers';
import { newToast } from '../../../actions/shared';

const Answer = ({ answer }) => {
  const dispatch = useDispatch();
  const wealthTypeOptions = useSelector((state) => state.wealthTypes);

  const [formData, setFormData] = useState({
    body: answer.body,
    wealthTypeName: answer.wealthTypeName,
    points: answer.points,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateAnswer(answer._id, formData));
    newToast("Answer updated.")
  }

  const handleClick = () => {
    let wantToDelete = window.confirm('Are you sure you want to delete this answer?');
    if (wantToDelete) {
      dispatch(deleteAnswer(answer._id))
    }
  }

  if (wealthTypeOptions.length < 1) { return <div></div> }

  const defaultWealthType = wealthTypeOptions[0];

  const renderWealthSelection = () => {
    return (
      <select defaultValue={answer.wealthTypeName} value={formData.wealthTypeName} onChange={(e) => setFormData({ ...formData, wealthTypeName: e.target.value })} name="wealthTypeName" id="wealthTypeName" >
        {wealthTypeOptions.concat({ wealthTypeName: "NULL" }).map((op) => {
          return <option value={op.wealthTypeName}>{op.wealthTypeName}</option>
        })}
      </select >
    )
  }

  return (
    <div className="admin-answer">
      <form onSubmit={handleSubmit} className='form-group'>
        <div className='cols'>
          <label htmlFor='body'>Answer</label>
          <input name="body" onChange={(e) => setFormData({ ...formData, body: e.target.value })} value={formData.body} className="form-control center" id="body" aria-describedby="body" type="text" />
        </div>
        <div className='cols mb-4'>
          <label htmlFor='wealthType'>Wealth Type</label>
          {renderWealthSelection()}
        </div>
        <button type="submit" className="btn btn-primary mx-4">Save Answer</button>
        <button onClick={() => handleClick()} className='btn btn-danger'>Delete</button>
      </form>
    </div>
  )
}

export default Answer;

