import { CREATE_QUESTION, SET_QUESTION, DELETE_QUESTION, FETCH_QUESTION, UPDATE_QUESTION, BACK_QUESTION } from '../types/index';

export default (question = null, action) => {
  switch (action.type) {
    case CREATE_QUESTION:
      return action.payload
    case FETCH_QUESTION:
      return action.payload;
    case UPDATE_QUESTION:
      return question;
    case SET_QUESTION:
      return action.payload;
    case BACK_QUESTION:
      return action.payload.previousQuestion;
    default:
      return question;
  }
}