import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createQuestion } from '../../actions/questions'
import { useNavigate } from 'react-router-dom';

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question);
  const [formData, setFormData] = useState({
    body: "",
    type: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createQuestion(formData));
    navigate("/questions");
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name"><p className='bold'>Question Body</p></label>
          <p className='small'>Enter the text for the body of your question</p>
          <input onChange={(e) => setFormData({ ...formData, body: e.target.value })} value={formData.name} className="form-control" id="body" aria-describedby="body" />
        </div>
        <button type="submit" className="btn btn-primary">Create Question</button>
      </form>
    </div>
  )
}

export default Form;