import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSetting, deleteSetting } from '../../../actions/settings';
import { newToast } from '../../../actions/shared';
const Setting = ({ setting }) => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    key: setting.key,
    value: setting.value,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateSetting(setting._id, formData));
    newToast("Setting edited.")
  }

  const handleDelete = () => {
    let wantToDelete = window.confirm("Are you sure you want to delete this setting?");
    if (wantToDelete) {
      dispatch(deleteSetting(setting._id))
      newToast("Setting deleted.")

    }
  }

  return (
    <div className='new-question-form'>
      <h2>Setting</h2>
      <div>
        <form onSubmit={handleSubmit} className='form-group'>
          <label htmlFor='key'>Key</label>
          <input name="key" onChange={(e) => setFormData({ ...formData, key: e.target.value })} value={formData.key} className="form-control center" id="key" aria-describedby="key" type="text" />
          <label htmlFor='value'>Value</label>
          <input name="value" onChange={(e) => setFormData({ ...formData, value: e.target.value })} value={formData.value} className="form-control center" id="value" aria-describedby="value" type="text" />
          <button type="submit" className="btn btn-primary">Save Setting</button>
          <button onClick={() => handleDelete()} className="btn btn-danger">Delete Setting</button>
        </form>
      </div>
    </div>
  )
}

export default Setting;