import { all } from 'axios';
import * as api from '../api';
import { ADD_SUBMISSION, DELETE_SUBMISSION, ADD_ANSWER_TO_SUBMISSION } from '../types/index';


export const addAnswerToSubmission = (answerData) => async (dispatch) => {
  dispatch({ ADD_ANSWER_TO_SUBMISSION, payload: answerData.body });
}

// export const createSubmission = (submissionData) => async (dispatch) => {
//   console.log('submissionData', submissionData);

//   try {
//     const { data } = await api.createSubmission(submissionData);
//     dispatch({
//       type: ADD_SUBMISSION,
//       payload: data
//     });
//   } catch (error) {
//     console.log(error.message);
//   }
//   dispatch({
//     type: DELETE_SUBMISSION,
//     payload: '',
//   })
// }

export const deleteSubmission = () => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SUBMISSION,
      payload: '',
    });
  } catch (error) {
    console.log(error.message);
  }
}

