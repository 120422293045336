import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions } from '../../../actions/questions';
import Question from './Question';
import Form from './Form';
import DragDropList from './TestDrag';
const Questions = () => {

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions);

  const [items, setItems] = useState(questions)

  useEffect(() => {
    dispatch(getQuestions());
    setItems(questions)
  }, [])

  // const saveResort = () => {
  //   console.log('saving')
  //   console.log('items', items)
  // }

  if (!questions || questions.length < 1) { return <div>...</div> }
  console.log('items from parent')
  const renderQuestions = () => {
    return questions.map((question, index) => {
      return <Question items={items} questions={questions} setItems={setItems} index={index} question={question} key={`question-${index}`} />
    })
  }

  return (
    <div className='w-half center overflow-auto'>
      {renderQuestions()}
    </div>
  )
}

export default Questions;