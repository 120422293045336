import { all } from 'axios';
import * as api from '../api';
import { FETCH_ANSWERS, FETCH_ANSWER, CREATE_ANSWER, DELETE_ANSWER, UPDATE_ANSWER, SET_CURRENT_SELECTED_ANSWER } from '../types/index';

export const getAnswers = () => async (dispatch) => {
  try {
    const { data } = await api.fetchAnswers();
    dispatch({
      type: FETCH_ANSWERS,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}

export const getAnswer = (answerId) => async (dispatch) => {
  try {
    const { data } = await api.fetchAnswer(answerId);
    dispatch({
      type: FETCH_ANSWER,
      payload: data
    })
  } catch (error) {
    console.log(error)
  }
}

export const createAnswer = (questionId) => async (dispatch, getState) => {
  try {
    const { data } = await api.createAnswer(questionId, getState().auth.token);
    const { allAnswers } = await api.getAnswersForQuestionWithId(questionId);

    console.log('all answers', allAnswers);
    dispatch({
      type: CREATE_ANSWER,
      payload: data
    });
    dispatch({
      type: FETCH_ANSWERS,
      payload: allAnswers
    });
  } catch (error) {
    console.log(error)
  }
}

export const updateAnswer = (answerId, answerParams) => async (dispatch, getState) => {
  console.log('answerId', answerId);
  console.log('answerParams', answerParams);
  try {
    const { data } = await api.updateAnswer(answerId, answerParams, getState().auth.token);
    dispatch({
      type: UPDATE_ANSWER,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
}

export const deleteAnswer = (answerId) => async (dispatch, getState) => {
  try {
    await api.deleteAnswer(answerId, getState().auth.token);
    dispatch({
      type: DELETE_ANSWER,
      payload: answerId
    });
  } catch (error) {
    console.log(error);
  }
}

export const setCurrentAnswer = (answer) => async (dispatch, getState) => {
  dispatch({
    type: SET_CURRENT_SELECTED_ANSWER,
    payload: answer,
  })
}