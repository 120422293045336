import React from 'react';
import Form from './Form';

const NewQuestion = () => {
  return (
    <div>
      <h1>New Question</h1>
      <Form />
    </div>
  )
}

export default NewQuestion;