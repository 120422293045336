import * as api from '../api';

import { toast } from 'react-toastify';
import { START_UP, FETCH_QUESTIONS, SET_QUESTION, GET_ANSWERS_FOR_QUESION, FETCH_WEALTHTYPES, CLEAR_REDIRECT_URL, START_QUIZ } from '../types';

export const newToast = (message) => {
  toast(message);
}

export const startUp = () => async (dispatch) => {

  const wealthTypeData = await api.fetchWealthTypes();
  const wealthTypes = wealthTypeData.data;
  const questionsData = await api.fetchQuestions();
  const questions = questionsData.data;
  const startingQuestion = questions[0];

  const answersForQuestionData = await api.getAnswersForQuestionWithId(startingQuestion._id);
  const answersForQuestion = answersForQuestionData.data;
  console.log('answersForQuestion', answersForQuestion)

  dispatch({
    type: FETCH_QUESTIONS,
    payload: questions,
  });
  dispatch({
    type: GET_ANSWERS_FOR_QUESION,
    payload: answersForQuestion,
  });
  dispatch({
    type: SET_QUESTION,
    payload: startingQuestion,
  });
  dispatch({
    type: FETCH_WEALTHTYPES,
    payload: wealthTypes,
  });
  dispatch({
    type: CLEAR_REDIRECT_URL,
    payload: ''
  })
  dispatch({
    type: START_QUIZ,
    payload: START_QUIZ
  })

}

export const setQuestion = (question) => async (dispatch, getState) => {
  dispatch({
    type: SET_QUESTION,
    payload: question,
  });
}