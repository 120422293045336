import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWealthType, deleteWealthType } from '../../../actions/wealthTypes';
import { newToast } from '../../../actions/shared';
const WealthType = ({ wealthType }) => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    wealthTypeName: wealthType.wealthTypeName,
    link: wealthType.link,
    activeCampaignTagId: wealthType.activeCampaignTagId,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateWealthType(wealthType._id, formData));
  }

  const deleteItem = () => {
    const wantToDelete = window.confirm("Are you sure you want to delete this?")
    if (wantToDelete) {
      dispatch(deleteWealthType(wealthType._id));
      newToast("Deleted Wealth Type.");
    }
  }

  return (
    <form onSubmit={handleSubmit} className='mb-3 border border-primary p-3'>
      <div className=''>
        <label htmlFor='wealthTypeName'>Name</label>
        <input name="wealthTypeName" onChange={(e) => setFormData({ ...formData, wealthTypeName: e.target.value })} value={formData.wealthTypeName} className="form-control center margin-b rounded border border-primary" id="wealthTypeName" aria-describedby="wealthTypeName" type="text" />
      </div>
      <div className=''>
        <label htmlFor='link'>Link</label>
        <input name="link" onChange={(e) => setFormData({ ...formData, link: e.target.value })} value={formData.link} className="form-control center margin-b rounded border border-primary" id="link" aria-describedby="link" type="text" />
      </div>
      {/* <div>
        <label htmlFor='activeCampaignTagId'>activeCampaignTagId</label>
        <input name="activeCampaignTagId" onChange={(e) => setFormData({ ...formData, activeCampaignTagId: e.target.value })} value={formData.activeCampaignTagId} className="form-control center" id="activeCampaignTagId" aria-describedby="activeCampaignTagId" type="text" />
        <p>Active Campaign Tag Id Comes from AC API</p>
      </div> */}
      <div className='d-grid'>
        <button type="submit" className="btn btn-primary mb-3">Save</button>
        <hr />
        <button onClick={deleteItem} className='btn btn-danger mb-3'>Delete</button>
      </div>
    </form>

  )
}

export default WealthType;